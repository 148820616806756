.header_filter_width {
    width: 92px;
  }

.MuiTablePagination-spacer {
    flex: 0 !important;
    /* flex: 0 1 100%; */
}

.MuiTablePagination-displayedRows {
  margin-left: 50px;
  margin-right: 50px;
}
